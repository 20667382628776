/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/** swiper styles **/
@import '~swiper/scss';
@import '~swiper/scss/zoom';
@import '~@ionic/angular/css/ionic-swiper';

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

@font-face {
    font-family: 'regular';
    src: url('./assets/fonts/Poppins-Regular.ttf');
}

@font-face {
    font-family: 'medium';
    src: url('./assets/fonts/Poppins-Medium.ttf');
}

@font-face {
    font-family: 'semi-bold';
    src: url('./assets/fonts/Poppins-SemiBold.ttf');
}

@font-face {
    font-family: 'bold';
    src: url('./assets/fonts/Poppins-Bold.ttf');
}

* {
    font-family: 'regular';
}


ion-toolbar {
  color:#ffffff;
  --color: #ffffff;
  background: #df2077;
  --background: #df2077;
}

ion-title {
  font-size: 13px;
}


.alert-wrapper {
 

  border-radius: 13px !important;
  min-width: calc(100% - 58px) !important;
  max-height: 484px !important;
  height: auto;

  .alert-head {
      background: var(--ion-color-primary);
      color: #ffffff;
      padding: 20px 27px 16px 27px;

      h2 {
          text-transform: uppercase;
          letter-spacing: .5px;
          font-size: 0.6rem;
          color: var(--text-black);
          font-weight: 300;
      }
  }

  .alert-radio-group {
      max-height: 291px !important;

      .alert-radio-button {
          .alert-button-inner {
              .alert-radio-label {
                  font-size: 1.2rem;
                  color: var(--ion-color-dark);
              }
          }
      }
  }

  .alert-button-group {
      padding: 0;
      display: flex;
      align-items: center;
      position: relative;

      &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: 1.5px;
          height: 100%;
          background-color: var(--ion-color-primary); //var(--ion-color-light);
          z-index: 9999;
          margin: 0 auto;
      }

      .alert-button {
          color: #ffffff;
          background: var(--ion-color-primary);
          height: 43px;
          text-align: center;
          margin: 0;
          width: 50%;

          .alert-button-inner {
              width: 100%;
              display: block;
              height: fit-content;
              font-size: .95rem;
              text-transform: none;
          }
      }
  }
}

/** Ionic CSS Variables **/
:root {
    /** primary **/
    --ion-color-primary: #df2077;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;
  
    /** secondary **/
    --ion-color-secondary: #890e67;
    --ion-color-secondary-rgb: 61, 194, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #36abe0;
    --ion-color-secondary-tint: #50c8ff;
  
    /** tertiary **/
    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;
  
    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;
  
    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;
  
    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;
  
    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;
  
    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;
  
    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
  }


  .grid_product_div {
    margin-bottom: 20px;

    .image_box {
        width: 100%;
        height: 140px;
        border-radius: 5px;
        background: #f3f3f3;

        .like_div {
            height: 34px;
            width: 34px;
            border-radius: 10px;
            // background: lightgrey;
            background: rgba(0,0,0,0.2);
            position: absolute;
            top: 5px;
            right: 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            ion-icon {
                color: red;
                font-size: 18px;
            }
        }
    }
}


.bg_image{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
  



  *[scrollX=true] {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -ms-overflow-style: none;
  
    &::-webkit-scrollbar {
        display: none;
    }
  
    .scrollX {
        flex: 0 0 auto;
    }
  }
 
 

ion-label {
    display: block;
    --font-family: 'regular';
    font-family: 'regular';
}

ion-button {
    margin: 0px;
    font-family: 'medium';
    letter-spacing: 1px;
    --border-radius: 10px;
    font-size: 16px;
    height: 48px;
}

ion-input, ion-select {
    border-radius: 10px;
    border: 1px solid rgb(150, 149, 149);
    height: 48px;
    --padding-start: 10px !important;
    font-family: 'regular' !important;
}


.forgot_lbl {
    text-align: right;
    margin-bottom: 30px;

    span {
        color: var(--ion-color-primary);
    }
}



.title_lbl {
    font-family: 'bold';
    font-size: 30px;
    margin-bottom: 20px;
}

.sub_title_lbl {
    color: grey;
    margin-bottom: 24px;
}

ion-title {
    font-weight: normal;
    font-family: 'medium';
    letter-spacing: 0.5px;
    font-size: 17px;
}


ion-modal.choose_product {
    --height: 100%;
    align-items: flex-end;

    .modal-wrapper {
        width: 100% !important;
        //border-top-left-radius: 5px;
        //border-top-right-radius: 5px;
        //box-shadow: 0 -4px 5px -4px rgba(0,0,0,0.4);
    }
    &.bottom {
        align-items: flex-end !important;
    }
}

.back_image {
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: relative !important;
}

.bg_image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.product_slider {
    display: flex;
    width: 100%;
    overflow: scroll;
    margin-bottom: 16px;

    .product_div {
        margin-right: 16px;

        .image_box {
            width: 120px;
            //height: 110px;
            height: 80px;
            border-radius: 16px;
            background: lightgrey;
            margin-bottom: 5px;
        }

        .grey_lbl {
            color: grey;
            font-size: 13px;
        }

        .name_lbl {
            font-family: 'medium';
            font-size: 14px;
        }
    }
}

.left {
    padding-left: 8px !important;
}

.right {
    padding-right: 8px !important;
}


.grid_product_div {
    margin-bottom: 20px;

    .image_box {
        width: 100%;
        height: 170px;
        border-radius: 16px;
        background: #f3f3f3;

        .like_div {
            height: 34px;
            width: 34px;
            border-radius: 10px;
            // background: lightgrey;
            background: rgba(0,0,0,0.2);
            position: absolute;
            top: 5px;
            right: 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            ion-icon {
                color: red;
                font-size: 18px;
            }
        }
    }
    .pro_info {
        margin-top: 10px;
        .grey_lbl {
            color: grey;
            font-size: 14px;
        }
        .pro_name {
            font-family: 'medium';
            font-size: 16px;
        }
        .pro_price {
            font-family: 'medium';
            margin-bottom: 5px;
        }

        .pro_flex {
            display: flex;

            div {
                display: flex;
                align-items: center;
                margin-right: 16px;

                ion-icon {
                    color: var(--ion-color-primary);
                }
                ion-label {
                    margin-left: 8px;
                    font-size: 14px;
                    font-family: 'medium';
                }
            }
        }
    }
}

.single_post {
   // background: white;
    padding: 16px;
    margin-bottom: 12px;
    border-bottom: 8px solid rgb(219, 217, 217);

  .user_div {
      display: flex;
      margin-bottom: 10px;
      position: relative;

      .user_image {
          width: 46px;
          height: 46px;
          min-width: 46px;
          background: lightgray;
          border-radius: 100%;
          .flag {
            height: 20px;
            text-align: center;
            color: white;
            font-weight: bold;
            border: 2px solid #fff;
            width: 20px;
            font-size: 13px;
            border-radius: 100%;
            //background: rgb(29, 169, 224);
            position: absolute;
            bottom: 0px;
            right: 0px;
        } 

        .verify {
          height: 18px;
          text-align: center;
          color: white;
          font-weight: bold;
          border: 2px solid #fff;
          width: 18px;
          font-size: 13px;
          border-radius: 50%;
          background: rgb(29, 169, 224);
          position: absolute;
          bottom: 0px;
          left: 0px;
        }
      }

      .user_detail {
          margin-left: 12px;

          .name_badge {
            display: flex;
            flex-direction: row;
          .name_lbl {
            font-family: 'medium';
            font-size: 15px;
            margin-bottom: 2px;
          }
          
          .badges{
                height: 18px;
                text-align: center;
                margin-left: 5px;
                color: white;
                font-weight: bold;
                border: 2px solid #fff;
                width: 18px;
                font-size: 12px;
                border-radius: 50%;
                background: rgb(29, 169, 224);
                margin-top:3px;
          }

          .badgesx{
            height: 18px;
            text-align: center;
            margin-left: 5px;
            color: white;
            font-weight: bold;
            border: 2px solid #fff;
            width: 18px;
            font-size: 12px;
            border-radius: 50%;
            //background: rgb(29, 169, 224);
            margin-top:3px;
      }
        }

          .grey_lbl {
              color: grey;
              font-size: 12px;
          }
      }

      .more_icn {
          position: absolute;
          right: 0;
          font-size: 20px;
      }
  }

  .about_lbl {
      font-size: 14px;
      margin-bottom: 10px;
  }

  .image_box {
      width: 100%;
      height: 370px;
      border-radius: 20px;
      background: lightgrey;
  }

  .action_div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;

      .like_div {
          display: flex;

          div {
              display: flex;
              margin-right: 16px;

              ion-icon {
                  font-size: 24px;
                  color: grey;
              }

              img {
                  padding-bottom: 2px;
              }

              ion-label {
                  font-family: 'medium';
                  margin-left: 10px;
              }
          }

          .share_lbls {
              font-size: 13px;
          }

          .share_lbl {
            margin-left: 15px;
            font-size: 13px;
        }
      }

      .bookmark_flex {
          ion-icon {
              color: grey;
              font-size: 24px;
              margin-left: 20px;
          }
      }
  }
}





.scroll-content{
    overflow-y:auto!important;
    -ms-overflow-style: none;
  
    &::-webkit-scrollbar {
        display: none;
    }
}

  *[scrollX=true] {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -ms-overflow-style: none;
  
    &::-webkit-scrollbar {
        display: none;
    }
  
    .scrollX {
        flex: 0 0 auto;
    }
  }

  .popover_setting {
    --ion-backdrop-opacity: 0.1; 
	--box-shadow: 80px;
    border-radius: 20px;
    --border-radius: 20px;
    .popover-arrow {
        display: none;
    }
    
    .popover-content {
		border-radius: 20px;
        --border-radius: 20px;
        //left: 10px !important;
        width: calc(100% - 50px);
    }
}

ion-popover::part(content) {
    border-radius: 20px;
    width: calc(100% - 80px);
  }

  .menu{
    margin-top: 12.5px;
    border-color: #000000;
    //box-shadow: 1px 2px 2px rgb(204, 199, 199);
  }
  .ion-item {
    text-align: center;
    align-items: center;


  }

  .menu-icon{
    font-size: 35px;
    font-weight: 900px;
    margin-left: 5px;
    

  }


  .close-icon-menu{
    margin-left: 20px;
  }

  .alight-menu{
    text-align: center;
    align-items: center;
  }

  
  
  .ion-menu::part(container) {
    border-radius: 0 20px 20px 0;
  
    box-shadow: 4px 0px 16px rgba(255, 0, 255, 0.18);
  }


  // USCIS
  .title-cards {
    font-size: 19px;

  }

  .description-cards {
    font-size: 14px;

  }

  .buttonFloat {
    color: #3171e0;
  }


  .searchbox {
    padding: 16px;
    margin: 16px;
    // box-shadow: rgba(149, 157, 165, 0.2) 0px 5px 15px;
    background-color: var(--ion-color-light);
    border-radius: 15px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    .icon {
      font-size: 20px;
      color: gray;
      padding-right: 10px;
    }
  }
  
  .title_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    .title {
      font-size: 16px;
      font-family: 'bold';
    }
  
    .btn {
      font-size: 14px;
      color: var(--ion-color-primary);
      font-family: 'bold';
    }
  }
  
  .banner_div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    margin: 0px 16px;
    border: 1px solid var(--ion-color-light);
    border-radius: 15px;
    position: relative;
    background-color: var(--ion-color-primary);
    box-shadow: 0px 0px 15px var(--box-shadow-color);
    --box-shadow-color: #f3f3f4;
  
    .content {
      width: 55%;
      display: flex;
      flex-direction: column;
  
      .discount {
        width: max-content;
        font-size: 26px;
        font-family: "bold";
        color: white;
      }
  
      .title {
        width: max-content;
        font-size: 18px;
        font-family: "bold";
       // color: white;
        padding: 10px 0px;
  
      }
  
      .text {
        text-align: start;
        font-size: 12px;
       // color: var(--ion-color-light);
      }
    }
  
    .img {
      width: 30%;
      height: 100%;
    }
  }
  
  .list_card {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    border-radius: 15px;
    padding: 14px;
    position: relative;
    margin: 16px 16px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  
    .img {
      width: 90px;
      height: 90px;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 16px;
      position: relative;
  
      .img_detail {
        position: absolute;
        width: 90%;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%, 0%);
        color: var(--ion-color-primary);
        background-color: white;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 5px 15px var(--box-shadow-color);
        --box-shadow-color: #f3f3f4;
  
        .discount {
          font-size: 12px;
          font-family: "medium";
        }
  
        .discount_type {
          font-size: 10px;
        }
      }
    }
  
    .detail {
      width: 70%;
      margin-left: 14px;
      display: flex;
      flex-direction: column;
  
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 0px;
  
        .title {
          font-size: 16px;
          font-family: 'bold';
          color: #000;
        }
  
        .text {
          font-size: 14px;
          font-family: 'medium';
          color: #000;
        }
  
        .price {
          font-size: 18px;
          font-family: 'bold';
          color: var(--ion-color-primary);
        }
  
        .color_txt {
          font-size: 12px;
          font-family: 'bold';
          color: var(--ion-color-primary);
          padding: 0px 5px;
        }
  
        .small_text {
          font-size: 10px;
          font-family: 'medium';
          color: var(--ion-color-medium);
        }
  
        .rating {
          display: flex;
          align-items: center;
  
          .star {
            font-size: 12px;
          }
        }
      }
    }
  }
  
  .grid_card {
    padding: 16px 4px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  
    .col_div {
      width: 50%;
  
      .hotel_card {
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 15px;
        padding: 12px;
        position: relative;
        margin: 8px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  
        .img {
          height: 120px;
          background-position: center center;
          background-size: cover;
          background-repeat: no-repeat;
          border-radius: 16px;
        }
  
        .detail {
          padding-top: 6px;
  
  
          .title {
            font-size: 14px;
            font-family: 'bold';
          }
  
          .text {
            font-size: 14px;
            font-family: 'medium';
            color: var(--ion-color-medium);
          }
  
          .price {
            font-size: 16px;
            font-family: 'bold';
            color: var(--ion-color-primary);
          }
  
          .color_txt {
            font-size: 12px;
            font-family: 'bold';
            color: var(--ion-color-primary);
            padding: 0px 5px;
          }
  
          .small_text {
            font-size: 10px;
            font-family: 'medium';
            color: var(--ion-color-medium);
          }
  
          .rating {
            display: flex;
            align-items: center;
            padding: 6px 0px;
  
            .star {
              font-size: 12px;
            }
          }
  
          .item {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
  
            .price_div {
              display: flex;
              align-items: flex-end;
            }
          }
        }
      }
    }
  }
  




  ion-button {
    margin: 0px;
  }
  
  .block_btn {
    --border-radius: 25px;
    height: 50px;
    font-size: 15px;
    --background: linear-gradient(to right, var(--ion-color-primary), var(--ion-color-secondary));
    text-transform: uppercase;
    font-family: "medium";
    letter-spacing: 1px;
  }
  
  .or_flex_div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  
    .line_div {
      width: 60px;
      height: 3px;
      background: gray;
    }
  
    ion-label {
      text-transform: uppercase;
      color: grey;
      margin-left: 16px;
      margin-right: 16px;
    }
  }
  
  .btn_flexs {
    display: flex;
    align-items: center;
    //justify-content: space-between;
    flex-direction: column;
  
    .btn_div {
      width: 100%;
      border-radius: 30px;
      display: flex;
      align-items: center;
      padding-left: 6px;
      padding-top: 6px;
      padding-bottom: 6px;
      padding-right: -6px;
  
      .img_box {
        width: 35px;
        min-width: 35px;
        height: 35px;
        border-radius: 100%;
        position: relative;
        margin-right: 8px;
  
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 18px;
        }

      
      }

      .img_boxs {
        width: 35px;
        min-width: 35px;
        height: 35px;
        border-radius: 100%;
        position: relative;
        margin-right: 8px;
  
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 25px;
          height: 20px;
        }
      }
  
      ion-label {
        font-family: "medium";
        text-transform: uppercase;
        font-size: 15px;
      }

      .icon_box {
        width: 20px;
       // min-width: 35px;
        height: 20px;
        //border-radius: 100%;
        position: relative;
        //margin-right: 3px;
  
        ion-icon {
          position: absolute;
          top: 50%;
          color: rgb(192, 191, 191);
          right: 20%;
          transform: translate(-50%, -50%);
         // width: 18px;
        }
      }
  
    }
  
    .fb_btn {
      border: 2px solid #aeb8d3;
      color: #3c5b9a;
    }
  
    .gp_btn {
      border: 2px solid #f8a4a3;
      color: #f53f3e;
    }
  }

  .btn_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //flex-direction: column;
  
    .btn_div {
      width: 100%;
      border-radius: 30px;
      display: flex;
      align-items: center;
      padding: 6px;
  
      .img_box {
        width: 30px;
        min-width: 30px;
        height: 30px;
        border-radius: 100%;
        position: relative;
        margin-right: 8px;
  
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 18px;
        }
      }
  
      ion-label {
        font-family: "medium";
        text-transform: uppercase;
        font-size: 15px;
      }
    }
  
    .fb_btn {
      border: 2px solid #aeb8d3;
      color: #3c5b9a;
    }
  
    .gp_btn {
      border: 2px solid #f8a4a3;
      color: #f53f3e;
    }
  }
  
  .bg_image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }


  :root {
    --white: #fff;
    --primary: #399df9;
    --bg-color: #f4f7f8;
    --bg-white: #fff;
    --transparent: rgba(0, 0, 0, 0);
    --text-light: #697070;
    --label-color: #484d4d;
    --text-light2: #aeb5b5;
    --text-dark: #2b2c30;
    --text-black: #000;
    --border-line-color: #ebebeb;
    --item-bg: #f1f1f1;
    --item-shadow: -15px 11px 30px -2px rgba(209, 209, 209, 0.75);
  }
  
  .bg_color {
    --background: var(--bg-color);
  }
  
  .bg_white {
    --background: var(--bg-white);
  }
  
  img {
    display: inline-block;
    &.lightThemeImg {
        display: block;
    }
    &.darkThemeImg {
        display: none;
    }
  }
  
  .d-flex {
    display: flex !important;
    align-items: center;
    width: 100%;
    .end {
        margin-left: auto !important;
        margin-right: 0 !important;
        display: block;
    }
  }
  
  .center_img {
    position: relative;
    overflow: hidden;
    img {
        &.crop_img {
            position: absolute;
            min-width: 100%;
            min-height: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
  }
  
  .button {
    &.btn {
        width: 100%;
        font-size: 1rem;
        font-weight: 500;
        height: 52px;
        --background: var(--primary);
        text-transform: none;
        color: var(--white);
        margin: 0;
        --box-shadow: none;
        letter-spacing: .5px;
        ion-icon {
            font-size: 1.4rem;
        }
        &:hover:not(.disable-hover) {
            --background: var(--primary);
        }
        &.button-outline {
            --background: var(--transparent);
            color: var(--white);
            --border-color: var(--primary);
            --border-width: 1px;
            &:hover:not(.disable-hover) {
                --background: var(--transparent);
            }
        }
        &.button-round {
            --border-radius: 50px !important;
        }
        &.button-block {
            --border-radius: 14px !important;
        }
        &.btn-dark {
            --background: var(--dark-btn-bg);
            &:hover:not(.disable-hover) {
                --background: var(--dark-btn-bg);
            }
        }
    }
  }
  
  
  .form {
    display: block;
    overflow: visible;
    position: relative;
    margin: 0 auto;
    width: calc(100% - 50px);
    ion-list {
        margin: 0;
        overflow: hidden !important;
        background: none;
        width: 100%;
        padding: 0;
    }
    ion-item {
        padding: 0;
        min-height: unset !important;
        margin: 0;
        --background: var(--transparent);
        --inner-padding-end: 0px;
        --inner-min-height: unset !important;
        --padding-start: 0;
        --highligh-color-focused: var(--transparent) !important;
        --background-hover: var(--transparent) !important;
        --background-focused: var(--transparent) !important;
        --ripple-color: var(--transparent) !important;
        box-shadow: none !important;
        overflow: visible !important;
        margin-bottom: 30px;
        ion-label {
            color: var(--label-color) !important;
            margin: 0;
            font-size: 1rem !important;
            letter-spacing: 0;
            font-weight: 500;
            max-width: 100% !important;
            width: 100% !important;
            flex: unset !important;
            padding-bottom: 10px;
        }
        .item_inner {
            width: 100%;
            .input_box {
                width: 100%;
                border: 2px solid var(--border-line-color);
                border-radius: 14px;
                padding: 0 16px;
            }
        }
        ion-icon {
            color: var(--primary);
            font-size: 1.2rem;
            min-width: 25px;
            padding: 0 3px;
            margin-top: 0;
            margin-bottom: 0;
            &.ion-accordion-toggle-icon {
                color: var(--text-dark);
            }
        }
        ion-textarea,
        ion-input {
            --padding: 0 !important;
            font-size: 1rem !important;
            font-weight: 500 !important;
            letter-spacing: 0;
            --padding-start: 0 !important;
            --padding-end: 0px !important;
            --padding-top: 12px !important;
            --padding-bottom: 12px !important;
            color: var(--text-dark);
            --placeholder-color: var(--text-light2) !important;
            --placeholder-opacity: 1;
            --placeholder-font-weight: 500 !important;
            margin: 0;
            flex: unset !important;
            input {
                --padding: var(--no-padding);
                &.native-input {
                    --padding: var(--no-padding);
                }
            }
        }
        &.item-select {
            ion-select {
                padding: 11px 0;
                font-size: 1rem !important;
                font-weight: 500 !important;
                display: flex;
                letter-spacing: 0;
                align-items: center;
                width: 100%;
                max-width: 100%;
                color: var(--text-dark);
                --placeholder-color: var(--text-light2) !important;
                --placeholder-opacity: 1;
                &::part(icon) {
                    display: none !important;
                }
            }
            ion-icon {
                &.zmdi-caret-down {
                    font-size: 2rem;
                    color: var(--text-black);
                    line-height: normal;
                    height: 35px;
                    min-width: fit-content;
                }
            }
        }
        &.item-datetime {
            ion-datetime {
                padding: 15px 0;
                font-size: 1.1rem !important;
                font-weight: 500 !important;
                //color: var(--text-black);
                --placeholder-color: var(--text-light) !important;
            }
        }
    }
    ion-row {
        margin: 0 -5px;
    }
  }
  
 
  
  ion-spinner {
    --spinner-color: var(--primary);
  }
  
  ion-loading {
    --spinner-color: var(--primary);
  }
  
  ion-select-popover {
    ion-radio {
        --color-checked: var(--primary);
    }
  }
  
 
  
  .swiper-slide img {
    max-height: unset;
  }
  
  .picker-wrapper {
    border: 0 !important;
    .picker-toolbar {
        border: none;
        height: 48px;
        padding: 3px;
        .picker-toolbar-button {
            .picker-button {
                font-size: 1.1rem;
                font-weight: 400 !important;
                letter-spacing: 0.6px !important;
                height: 40px;
                background: var(--primary);
                text-transform: none;
                color: var(--white);
                margin: 0;
                --box-shadow: none;
                width: fit-content;
                min-width: 106px;
                border-radius: 5px;
            }
        }
    }
    .picker-columns {
        .picker-above-highlight {
            border: none;
        }
        .picker-below-highlight {
            border: none;
        }
        .picker-opts {
            .picker-opt {
                font-size: 1.4rem;
                font-weight: 500;
                color: var(--text-black);
            }
        }
    }
  }
  
  
  
  
  /*ion-app[dir="rtl"] {
    --item-shadow: 15px 11px 30px -2px rgb(209 209 209 / 75%);
    ion-header ion-toolbar {
        ion-back-button {
            transform: rotate(180deg)!important;
        }
        ion-title {
            padding-right: 0 !important;
            padding-left: 15px !important;
        }
    }
    .d-flex .end {
        margin-left: 0 !important;
        margin-right: auto !important;
    }
   
 .ios ion-fab.fab-horizontal-center {
    margin-inline-start: -35px;
    bottom: calc(45px - 2%);
  }

  .ios ion-fab.fab-horizontal-start {
    top: calc(55px - 1%);
  }

  ion-content {
    //position: fixed;
    height: 100%;
  padding: 0;
  overflow: auto;
  margin: 0;
  -webkit-overflow-scrolling: touch;
    //--background: #ffffff;
  }

  .no-scroll {

    --offset-bottom: auto!important;
    
    --overflow: hidden;
    
    overflow: auto;
    
    &::-webkit-scrollbar {
    
      display: none;
    
    }
    }

    html {
      height: 100vh;
      width: 100vw;
      overflow: hidden;
    }
    
    body {
      height: 100%;
      padding: 0;
      overflow: auto;
      margin: 0;
      -webkit-overflow-scrolling: touch;
    }

  }*/

  